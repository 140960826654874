# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
ready = ->

  $('#prefectures-area a').click (e)->
    e.preventDefault()
    id = $(this).attr('data-prefectures')
    $target = $('#'+id)
    $target.addClass('active');

  $('.prefectures-back').click (e)->
    e.preventDefault()
    $('.region-map-sp .prefectures').removeClass('active');

  $('.section-area-prefecture').fadeOut(0)
  $('#area-region a').click (e)->
    e.preventDefault()
    $('.section-area-prefecture').fadeOut(200)
    $target = $('#section-area-'+$(this).attr('href'))
    $target.stop(true,false).fadeIn 200,()->
      $('body,html').animate({scrollTop:$target.offset().top},200)

  $('.switch').on 'click touch', (e)->
    e.preventDefault()
    $(this).next('ul').slideToggle ->
      if $(this).css("display") == 'block'
        $(this).prev().addClass('on')
      else
        $(this).prev().removeClass('on')

$(document).ready(ready)
$(document).on('page:load', ready)

$(document).on 'click', '.list-search-btn', (e)->
  e.preventDefault()
  top = $('.section-search-filter').offset().top;
  $('html,body').animate({ scrollTop: top - 10 }, 300);
