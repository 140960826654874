$(function () {
    new Swiper('.swiper-container', {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        spaceBetween: 0,
        loop: true,
        speed: 500
    });

    var $searchAreaSp_lists = $(".content-searchAreaSp-lists"),
            $searchAreaSp_li = $(".content-searchAreaSp-lists li"),
            $searchAreaSp_lists_child = $(".content-searchAreaSp-lists-child"),
            $searchAreaSp_lists_child_ul = $(".content-searchAreaSp-lists-child ul"),
            $searchAreaSp_lists_title = $(".content-searchAreaSp-lists-child .lists-title"),
            $searchAreaSp_lists_p = $(".content-searchAreaSp-lists-child .lists-title p"),
            $searchAreaSp_return = $(".content-searchAreaSp-lists-child .return-btn");


    $searchAreaSp_li.on("click", function (e) {
        e.preventDefault();

        $searchAreaSp_lists_child_ul.css({display: "none"});
        $searchAreaSp_lists_p.css({display: "none"});

        var index = $(this).index();

        $searchAreaSp_lists.css({display: "none"});
        $searchAreaSp_lists_child.fadeIn();
        $searchAreaSp_lists_title.fadeIn();

        $searchAreaSp_lists_child_ul.eq(index).css({display: "block"});
        $searchAreaSp_lists_p.eq(index).css({display: "block"});


    });

    $searchAreaSp_return.on("click", function (e) {
        e.preventDefault();

        $searchAreaSp_lists_child_ul.css({display: "none"});
        $searchAreaSp_lists_p.css({display: "none"});

        $searchAreaSp_lists.fadeIn();
        $searchAreaSp_lists_child.css({display: "none"});
        $searchAreaSp_lists_title.css({display: "none"});

    });

//MAP HOVER
    var $parent_button = $('.content-searchArea-map .map-button');

    $parent_button.hover(
            function () {
                $(this).find('ul').slideToggle();
            }
    );

    //*** PC modal ***//

    var $area_target = $("#area-target"),
            $cover = $(".cover"),
            $search_modal = $(".search-modal");
    $search_li = $(".search-modal .modal-lists li a.sidebar-city"),
            $modal_content = $(".modal-content");

    $area_target.on("mouseenter", function () {
        modal_in();
    });


    $cover.on("click", function () {
        modal_out();
    });

    $search_li.on("click", function (e) {
        e.preventDefault();
        var text = $(this).text();
        $search_li.removeAttr('style');
        $(this).css({'textDecoration': 'underline'});
        $area_target.text(text);
        $('#search-near-form').attr('action', $(this).prop('href'));
        $('#search-near-form').submit();
    });

    $sp_search_area_select = $('#sp-search-city-select');
    $('#sp-bukken-search').attr('action', $('#sp-search-city-select option:selected').val());
    $sp_search_area_select.on('change', function () {
        $('#sp-bukken-search').attr('action', $('#sp-search-city-select option:selected').val());
    });

    function modal_in() {
        $cover.stop().fadeIn();
        $search_modal.stop().fadeIn();
    }

    function modal_out() {
        $cover.stop().fadeOut();
        $modal_content.stop().fadeOut();
    }

    //*** PC modal ***//


    //*** SP modal ***//

    //メニュー
    var $menu_open = $("#menu-open"),
            $sp_menu = $(".sp-menu"),
            $cover_menu = $(".cover-menu"),
            $menu_close = $(".menu-close");

    $menu_open.on("click", function (e) {
        e.preventDefault();
        sp_menu_in();
    });

    $menu_close.on("click", function (e) {
        e.preventDefault();
        sp_menu_out();
    });
    $cover_menu.on("click", function () {
        sp_menu_out();
    });

    function sp_menu_in() {
        $sp_menu.stop().fadeIn();
        $cover_menu.stop().fadeIn();
    }

    function sp_menu_out() {
        $sp_menu.stop().fadeOut();
        $cover_menu.stop().fadeOut();
    }


    //絞り込み検索
    $(document).on('click', '#sp-search-btn', function (e) {
        e.preventDefault();
        search_modal_in();
    });

    $(document).on('click', '.modal_close', function (e) {
        e.preventDefault();
        modal_out();
    });

    function search_modal_in() {
        $cover.stop().fadeIn();
        var scroll_top = $(window).scrollTop();

        var $sp_search_modal = $(".sp-search-modal");
        $sp_search_modal.css({top: (scroll_top + 10) + "px"});
        $sp_search_modal.stop().fadeIn();
    }

    //*** SP modal ***//


    //*** image preview ***//
    var $images_sub = $(".content-main-images-sub"),
            $images_sub_li = $(".content-main-images-sub li"),
            $images_main = $(".content-main-images-mainInner img"),
            $image_prev = $(".content-main-images-prev"),
            $image_next = $(".content-main-images-next"),
            image_count = $images_sub_li.length,
            current_index = 0;


    $images_sub_li.on("click", function () {

        if (!$(this).hasClass('active')) {
            var index = $(this).index();
            current_index = index;

            image_preview(current_index);

        }
    });

    $image_prev.on("click", function (e) {
        e.preventDefault();

        if (current_index == 0) {
            current_index = image_count - 1;
        } else {
            current_index = current_index - 1
        }

        image_preview(current_index);

    });

    $image_next.on("click", function (e) {
        e.preventDefault();

        if (current_index == image_count - 1) {
            current_index = 0;
        } else {
            current_index = current_index + 1
        }

        image_preview(current_index);

    });

    function image_preview(num) {

        var image = $images_sub_li.eq(num).find('img').attr('src');

        $images_main.attr('src', image);

        $images_sub_li.removeClass('active');

        $images_sub_li.eq(num).addClass('active');

    }

    $('.content-main-favorite img').on('click', function (e) {
        e.preventDefault();

        
        var nomal = "/assets/common/btn-favorite-3969b7d5011f10cee733a81d74f69148c26f7e733958eb9ea61acfe9dfc4bfd2.png"
        var active = "/assets/common/btn-favorite-active-aa70afcb5bd372134f34c954b27efc3e1e1bbf733e40116131835a1b92f546a6.png"

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).attr('src', nomal);
        } else {
            $(this).addClass('active');
            $(this).attr('src', active);
        }
    });

    $(document).on('click', '.content-main-tabSp #display-lists', function (e) {
        e.preventDefault();

        var $tab_lists = $(".content-main-tabSp #display-lists");
        var $tab_map = $(".content-main-tabSp #display-map");
        var $content_lists = $("#content-lists");
        var $content_map = $("#map");
        var map_caption = $(".map-state");
        var $content_paging = $("#content-paging");
        var $content_main_tabRight = $(".content-main-tabRight");

        if ($content_map.hasClass('display-on')) {
            $content_map.removeClass('display-on');
            $tab_map.removeClass('display-on');
            map_caption.removeClass('display-on');
        }
        if (!$content_lists.hasClass('display-on')) {
            $content_lists.addClass('display-on');
            $tab_lists.addClass('display-on');
        }
        $content_paging.css({display:"block"});
        $content_main_tabRight.css({display:"block"});
    });

    $(document).on('click', '.content-main-tabSp #display-map', function (e) {
        e.preventDefault();

        var $tab_lists = $(".content-main-tabSp #display-lists");
        var $tab_map = $(".content-main-tabSp #display-map");
        var $content_lists = $("#content-lists");
        var $content_map = $("#map");
        var map_caption = $(".map-state");
        var $content_paging = $("#content-paging");
        var $content_main_tabRight = $(".content-main-tabRight");

        if ($content_lists.hasClass('display-on')) {
            $content_lists.removeClass('display-on');
            $tab_lists.removeClass('display-on');
        }
        if (!$content_map.hasClass('display-on')) {
            $content_map.addClass('display-on');
            $tab_map.addClass('display-on');
            map_caption.addClass('display-on');
            google.maps.event.trigger(map, 'resize');
            handler.fitMapToBounds();
        }
        $content_paging.css({display:"none"});
        $content_main_tabRight.css({display:"none"});
    });
});
