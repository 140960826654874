(function() {
  var ready;

  ready = function() {
    $('.search-near-form').click(function(e){
      if (navigator.geolocation) {
        e.preventDefault();
        var timeoutVal = 10 * 1000 * 1000;
        navigator.geolocation.getCurrentPosition(
          displayPosition,
          displayError,
          { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 }
        );
      }
    });
  };

  function displayPosition(position) {
    document.getElementById('coordinates').value = position.coords.latitude + "," + position.coords.longitude;
    $('#search-near-form').submit();
  }

  function displayError(error) {
    var errors = {
      1: 'Permission denied',
      2: 'Position unavailable',
      3: 'Request timeout'
    };
    alert("Error: " + errors[error.code]);
  }

  $(document).ready(ready);
  $(document).on('page:load', ready);

}).call(this);