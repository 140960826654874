ready = ->

  # お気に入りリストの表示順
  $('.favorite-sort .sort-key').click (e)->
    e.preventDefault()
    $self = $(this)
    sort_key = $self.attr('data-sort-key')
    sort_order = $self.attr('data-sort-order')
    $list = $('.section-favorite-list').sort (a, b)->
      a = parseFloat $(a).attr(sort_key)
      b = parseFloat $(b).attr(sort_key)
      if a < b
        return -1
      if a > b
        return 1
      return 0
    if sort_order == 'desc'
      $list = $($list.get().reverse())
    $('#section-favorite-list').html($list)
    $('.favorite-sort .sort-key').removeClass('active')
    $self.addClass('active')

$(document).ready(ready)
$(document).on('page:load', ready)
