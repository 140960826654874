# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

t = undefined
$(document).on 'click', '#trunks-sort-summary', (t) ->
  t.preventDefault()
  if $(this).hasClass('active') then undefined else $('#q_sort_key').val('summary_monthly_fee')
  changeSortActive()
  $('.search-form-filter').submit()

$(document).on 'click', '#trunks-sort-space', (t) ->
  t.preventDefault()
  if $(this).hasClass('active') then undefined else $('#q_sort_key').val('spec_floor_space')
  changeSortActive()
  $('.search-form-filter').submit()

$(document).on 'click', '#trunks-sort-available', (t) ->
  checked = if @checked then 'on' else 'off'
  $('.sort_available').val(checked)
  $('.search-form-filter').submit()

$(document).on 'click touch', '#switch_title', (e)->
  e.preventDefault()
  $('#accordion_block').slideToggle ->
    if $(this).css("display") == 'block'
      $('#switch_title').addClass('on')
    else
      $('#switch_title').removeClass('on')

t = ->
  t = null
  if $('#trunks-sort-available').prop('checked') then t = 'on' else t = 'off'
  $('.sort_available').val(t)

$(document).ready(t)
$(document).on('page:load', t)

changeSortActive = () ->
  $('#trunks-sort-summary').toggleClass('active')
  $('#trunks-sort-space').toggleClass('active')
