# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
ready = ->
  # ▼▼ 検索時 ▼▼
  # 施設タイプのチェックボタン
  $('.spec_layout_type_checkbox_okunai').change ()->
    $('.spec_layout_type_checkbox_okunai').prop('checked', $(this).is(':checked'))
  $('.spec_layout_type_checkbox_okugai').change ()->
    $('.spec_layout_type_checkbox_okugai').prop('checked', $(this).is(':checked'))
  $('.spec_layout_type_checkbox_baiku').change ()->
    $('.spec_layout_type_checkbox_baiku').prop('checked', $(this).is(':checked'))
  $('.spec_layout_type_checkbox_okunai_sp').change ()->
    $('.spec_layout_type_checkbox_okunai_sp').prop('checked', $(this).is(':checked'))
  $('.spec_layout_type_checkbox_okugai_sp').change ()->
    $('.spec_layout_type_checkbox_okugai_sp').prop('checked', $(this).is(':checked'))
  $('.spec_layout_type_checkbox_baiku_sp').change ()->
    $('.spec_layout_type_checkbox_baiku_sp').prop('checked', $(this).is(':checked'))

  # 市区町村のチェックを絞り込み検索に反映させる
  $('.checkbox-city').change ()->
    $self = $(this)
    $('.city-'+$self.attr('data-cid')).prop('checked', $self.is(':checked')).change()

  # 部屋数の取得
  $('form.search-form-prefectures').change ()->
    $self = $(this)
    $.ajax
      url: '/update_progress.json'
      type: "GET"
      data: $self.serialize()
      success: (data)->
        $self.find('.results-value-trunks-count').html(data.trunks_count)

  # 一覧では絞り込み検索の項目変更で更新する
  $('form.search-form-filter').change ()->
    $self = $(this)
    $self.find('.input_pop_search_end').val('true');
    $(this).submit()
    $self.find('.input_pop_search_end').val('false');

  # 絞り込み検索の検索結果を見るボタンを押したとき
  $('.side-results-btn').click ()->
    $('body,html').animate({scrollTop:0},400)

  # 部屋の無い都道府県のリンクを外す
  if window.trunk_count_in_prefecture
    $.each window.trunk_count_in_prefecture, (key,data)->
      if data == 0
        $elem = $('.'+key)
        $elem.addClass('bukken_none').children('a').before('<span>'+$elem.children('a').html()+'</span>').remove()

  # キーワード検索
  $('.search-form-text input[type="submit"]').click (e)->
    if $(this).parents('.search-form').find('input[type="search"]').val() == ''
      e.preventDefault();
      alert 'キーワードを入力してください'

  $('.advance_trunk_search').on 'submit', ->
    search_submit(this)

  $('.trunk_search').on 'submit', ->
    search_submit(this)

  search_submit = (form) ->
    cities = undefined
    prefecture = undefined
    types = undefined
    type = undefined
    cities = $('[name=\'q[location_city_cont_any][]\']:checked').map((index, checkbox) ->
      $(this).data 'en'
    ).get()
    cities.sort()
    prefecture = $(form).children('input[name="prefecture_en_name"]').val()
    types = $('[name=\'q[layout_type_code_in][]\']:checked').map((index, checkbox) ->
      $(this).val()
    ).get()
    if types.length > 0
      test = types.toString()
      if types.toString() == ["1", "4"].toString()
        # 屋内トランクルーム
        type = 'okunai-trunkrooms'
      else if types.toString() == ["2", "5", "6"].toString()
        # 屋外トランクルーム
        type = 'okugai-containers'
      else
        # 屋外トランクルーム
        type = 'for-motorbikes'
      if cities.length > 0 and prefecture != undefined
        $(form).attr 'action', '/' + type + '/' + prefecture + '/' + cities[0]
      else
        $(form).attr 'action', '/' + type + $(form).attr 'action'
    else
      if cities.length > 0 and prefecture != undefined
        $(form).attr 'action', '/' + prefecture + '/' + cities[0]
    true

  # スマホ用「部屋の条件を絞り込み検索」の表示範囲設定
  if $('.side-results-wrap').length > 0 && $('.section-search-counter').length > 0 && $('.section-search-filter').length > 0
    $('.side-results-wrap').fadeOut(0);
    $(window).scroll ()->
      h1 = $('.section-search-counter').eq(0).offset().top + $('.section-search-counter').height();
      h2 = $('.section-search-filter').eq(0).offset().top - $(window).height();
      st = $(this).scrollTop()
      if st > h1 && st < h2
        $('.side-results-wrap').fadeIn(200)
      else
        $('.side-results-wrap').fadeOut(200)

  # スマホ用部屋詳細ページの下ナビ表示範囲設定
  if $('#sp-trunks-show-nav').length > 0 && $('.visible-xs .section-trunk-list').length > 0
    $(window).scroll ()->
      h1 = -1;
      h2 = $('.visible-xs .section-trunk-list').eq(0).offset().top - $(window).height();
      st = $(this).scrollTop()
      if st > h1 && st < h2
        $('#sp-trunks-show-nav').stop(true,false).fadeIn(200)
      else
        $('#sp-trunks-show-nav').stop(true,false).fadeOut(200)

$(document).ready(ready)
$(document).on('page:load', ready)

# お気に入りリストに追加するボタン
$(document).on 'click', '.detail-favorite-btn, .list-favorite-btn, .favorite-favorite-btn, .history-favorite-btn', (e)->
  e.preventDefault();
  $.cookie.defaults.path = "/"
  cn_favorite = "favorite_trunks"
  $self = $(this)
  trunk_id = $self.attr('data-trunk-id')
  favorites = []
  if $.cookie(cn_favorite)
    favorites = $.cookie(cn_favorite).split(",")

  n = $.inArray(trunk_id, favorites)
  if n == -1
    favorites.unshift(trunk_id)
    $self.addClass('favorite-on')
  else
    favorites.splice(n,1)
    $self.removeClass('favorite-on')
    # お気に入りリスト一覧ページでリストから削除したとき
    $('#favorite-trunk-'+trunk_id).fadeOut 200,()->
      $(this).remove();
      num = $('.section-favorite-list').length
      $('#favorite-trunks-num').html(num)
      if num <= 0
        $('#favorite-list').fadeOut(0)
        $('#favorite-none').fadeIn(200)
  # 同じページ内の同種ボタンにも反映させる
  $('.detail-favorite-btn, .list-favorite-btn, .favorite-favorite-btn, .history-favorite-btn').each ()->
    $self = $(this)
    tid = $self.attr('data-trunk-id')
    if trunk_id == tid
      if n == -1
        $self.addClass('favorite-on')
      else
        $self.removeClass('favorite-on')
  $.cookie cn_favorite, favorites, { expires: 365*20 }
  # ヘッダーメニューのカウントに反映させる
  $('#favorite_trunks_num').html(favorites.length);
  # サイドメニューのリストに反映させる
  $.ajax
    url: "/update_favorite",
    type: "GET",

