/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// import Rails from '@rails/ujs';
// Rails.start();

require("jquery")
import {} from 'jquery-ujs'

// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// console.log('Hello from Webpacker')

import 'layout.js'
import 'area.js'
// import 'lines.js'
// import 'prefectures.js'
import 'trunks.js'
import 'jquery.flexslider-min'
import 'common.js'
import 'swiper.min'
import 'navigator'
import 'favorite.js'
import 'lib/jquery.cookie'
